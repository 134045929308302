html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

#main-wrapper {
	min-height: 100%;
	position: relative;
}

header {
	width: 100%;
	z-index: 800;
}

footer {
	width: 100%;
	overflow: hidden;
	background: rgba(255, 255, 255, .1);

	.footer-title {
		text-transform: uppercase;
	}

	.sponsors {
		background: #fff;

		.sponsor-slick-container {
			height: 69px;
			overflow: hidden;

			.img-item {
				display: inline-block;
			}
		}
	}
}

header,
footer {
	a,
	.btn-link {
		color: #fff;

		&:hover {
			color: darken(#fff, 5%);
		}
	}
}

@media (min-width: 768px) {
	header {
		height: $header-size;
		position: fixed;
	}

	footer {
		position: absolute;
		bottom: 0;
		height: $footer-size;
	}

	#content-wrapper {
		padding-top: $header-size;
		padding-bottom: $footer-size;
	}
}

.logo {
	width: 209.27px;
	height: 90px;
}

.search-container {
	top: 29px; // to align with the logo
	z-index: 500;
	left: 50%;
	transform: translateX(-50%);
}

#mainNavigation {
	background-color: $main-blue;

	.navbar-nav {
		flex-basis: 100%;

		.nav-link {
			font-size: 1rem;
		}

		@media (min-width: 1200px) {
			.nav-link {
				font-size: 1.2rem;
			}
		}

		& > .nav-item {
			text-transform: uppercase;
			font-weight: bold;
		}
	}

	@media (min-width: 768px) {
		.navbar-collapse {
			align-self: flex-end;
		}
	}
}

.slogan {
	background-color: #fff;
	padding: 0.5rem 0;
	color: $main-blue;

	small {
		font-weight: inherit;
	}
}

.sponsor-title {
	font-family: Impact, sans-serif;
	font-size: 1.2rem;
	color: $main-red;
	text-transform: uppercase;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active,
.dropdown-item.active {
	color: #fff;
	background-color: rgba($main-blue, .8);
}

.gallerie-thumb, .gallerie-image {
	height: 140px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.gallerie-container {
    margin-bottom: 20px;
}

.gallerie-date {
    color: #f6c01e;
    font-weight: bold;
    font-size: 4em;
    width: 129px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-shadow: -1px 0 #0f218b, 0 1px #0f218b, 1px 0 #0f218b, 0 -1px #0f218b;
}

.sponsor-img {
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.carousel-link {
    position: relative;
    height: 69px;
    display: block;
}

.carousel-link img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#map-container {
	position: relative;
}

.legends {
	position: absolute;
	padding: 20px;
	background-color: #5a5c5e;
	top: 0;
	right: 0;
	margin-top: 10px;
	margin-right: 10px;
}

.legends .legend-item {
	width:10px;
	height:10px;
	margin-top: 5px;
	margin-right: 5px;
}

.legends .legend-item-free {
	background-color:#00ff00;
}

.legends .legend-item-prereserved {
	background-color:#588de2;
}

.legends .legend-item-reserved {
	background-color:#d69b1b;
}

.legends .legend-item-occupied {
	background-color:#ff0000;
}

#capture-transparent {
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.5;
	background-color: #fff;
}

#capture {
	background-color: #B7195D;
	padding: 20px;
	height: 260px;
	position: absolute;
	top: 61px;
	bottom: 0;
	width: 320px;
	left: 0;
	right: 0;
	margin: auto;
	margin: auto;
}

#capture .btn-reserved {
	position: absolute;
	right: 20px;
	bottom: 10px;
}

.program-day-title {
	text-transform: uppercase;
	border-bottom: 2px solid #fff;
	font-weight: bolder;
	font-size: large;
}

.program-time-title {
	color: #f6c01e;
	font-weight: bolder;
}

.program-description {
	text-transform: uppercase;
	font-weight: bolder;
}

.program-page img,
.news-page img {
	width: 100%;
	margin: 1em 0;
}

.news-page {
	margin: 50px 0; 
}

.program-col {
	padding-bottom: 2em;
}

.custome-table-responsive tbody td.first-cell {
	width: 30%
}


td.money-cell {
	width: 30%;
}

ol.craue_formflow_steplist {
	padding-left: 25px;
}

ol.craue_formflow_steplist li {
    height: 30px;
	position: relative;
	list-style-type: none;
	display: inline-block;
	margin-right: 22px;
	width: 11.83%;
	padding: 5px;
	background-color:#C1C8E2;
	color: #000000;
}

ol.craue_formflow_steplist li:after {
	content: "";
	position: absolute;
	top: 0;
	right: -20px;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-left: 20px solid #C1C8E2;
	border-bottom: 15px solid transparent;
}

ol.craue_formflow_steplist li:not(:first-child):before {
	content: "";
	position: absolute;
	top: 0;
	left: -20px;
	width: 0;
	height: 0;
	border-top: 15px solid #C1C8E2;
	border-left: 20px solid transparent;
	border-bottom: 15px solid #C1C8E2;
}

ol.craue_formflow_steplist li:first-child:before {
	content: "";
	position: absolute;
	width: 20px;
	height: 30px;
	top:0;
	left: -20px;
	background-color: #C1C8E2;
}

ol.craue_formflow_steplist li.craue_formflow_current_step {
	height: 40px;
	margin-right: 30px;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color:#8591C5;
	margin-left: 2px;
	color: #C1C8E2;
	font-weight: bold;
	color: #ffffff;
}

ol.craue_formflow_steplist li.craue_formflow_current_step:after {
	content: "";
	position: absolute;
	top: 0;
	right: -27px;
	width: 0;
	height: 0;
	border-top: 20px solid transparent;
	border-left: 27px solid #8591C5;
	border-bottom: 20px solid transparent;
}

ol.craue_formflow_steplist li.craue_formflow_current_step:first-child:before {
	content: "";
	position: absolute;
	width: 27px;
	height: 30px;
	top:0;
	left: -27px;
	background-color: #8591C5;
}

ol.craue_formflow_steplist li.craue_formflow_current_step:before {
	content: "";
	position: absolute;
	top: 0;
	left: -27px;
	width: 0;
	height: 0;
	border-top: 20px solid #8591C5;
	border-left: 27px solid transparent;
	border-bottom: 20px solid #8591C5;
}

ol.craue_formflow_steplist li.craue_formflow_done_step,
ol.craue_formflow_steplist li.craue_formflow_skipped_step {
	background-color:#8591C5;
	color: #C1C8E2;
}

ol.craue_formflow_steplist li.craue_formflow_done_step a {
	color: #C1C8E2;
}

ol.craue_formflow_steplist li.craue_formflow_done_step:after,
ol.craue_formflow_steplist li.craue_formflow_skipped_step:after {
	content: "";
	position: absolute;
	top: 0;
	right: -20px;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-left: 20px solid #8591C5;
	border-bottom: 15px solid transparent;
}

ol.craue_formflow_steplist li.craue_formflow_done_step:first-child:before,
ol.craue_formflow_steplist li.craue_formflow_skipped_step:before {
	content: "";
	position: absolute;
	width: 20px;
	height: 30px;
	top:0;
	left: -20px;
	background-color: #8591C5;
}

ol.craue_formflow_steplist li.craue_formflow_done_step:before {
	content: "";
	position: absolute;
	top: 0;
	left: -20px;
	width: 0;
	height: 0;
	border-top: 15px solid #8591C5;
	border-left: 20px solid transparent;
	border-bottom: 15px solid #8591C5;
}

#cashless-iframe {
	background: #FFFFFF;
	height: 500px;
	padding: 10px;
}

@media screen and (max-width: 1199px) {
	ol.craue_formflow_steplist li {
		width: 13.7%;
	}
}

@media screen and (max-width: 992px) {
	ol.craue_formflow_steplist li,
	ol.craue_formflow_steplist li.craue_formflow_current_step,
	ol.craue_formflow_steplist li.craue_formflow_done_step {
		display: list-item !important;
		list-style-type: decimal !important;
		margin-bottom: 10px !important;
		width: 150px;
	}
	ol.craue_formflow_steplist li.craue_formflow_current_step {
		margin-left: 0 !important;
	}
	ol.craue_formflow_steplist li:before {
		width: 0px !important;
		border: 0px !important;
		background-color:#C1C8E2;
		left: -27px !important;
	}
	ol.craue_formflow_steplist li.craue_formflow_current_step:before {
		/*width: 27px !important;*/
		background-color:#8591C5;
		left: -27px !important;
	}

	/*ol.craue_formflow_steplist li.craue_formflow_current_step:before {
		content: "" !important;
		position: absolute !important;
		width: 27px !important;
		height: 30px !important;
		top:0 !important;
		left: -27px !important;
		border: 0 !important;
	}*/
}

