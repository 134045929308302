#carousel {
	position: relative;
	height: 350px;
	overflow: hidden;

	.slick-container,
	.slick-container * {
		height: 100%;
	}

	@media screen and (max-width: 992px) {
		height: 250px;
	}

	.slick-container .img-item > div {
		background-position: center center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}

	.carousel-catchphrase {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

		.cursive-text {
			font-size: 5rem;
			font-family: 'Caveat Brush', cursive;
		}
	}
}
