@import "common_variables";
@import "app_variables";
@import "fonts";

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme.css";

@import "layout";
@import "carousel";
